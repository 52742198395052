import { useQuery } from '@tanstack/react-query';
import API from 'services/api/index';
import QUERY_KEYS from 'services/api/queryKeys';

export const useGetTravelAgency = (id?: number) => {
  const { data, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.GET_TRAVEL_AGENCIES, id],
    queryFn: () => API.ta.getTravelAgency(Number(id)),
    enabled: !!id
  });

  return {
    data: data,
    isLoading
  };
};

export const useGetTravelAgencies = (values?: any) => {
  const { data, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.GET_TRAVEL_AGENCIES, values],
    queryFn: () => API.ta.getTravelAgencies(values),
    enabled: !!values
  });

  return {
    data: data,
    isLoading
  };
};
