import { useState } from 'react';
import useModal from 'stores/useModal';

import { Button, Card, Form, FormProps, Input, Table } from 'antd';

import { useGetTravelAgencies } from 'hooks/useGetTravelAgencies';
import { columnTravelAgency } from 'constants/table';
import UpdateAndDeleteTravelAgency from 'components/modal/travel-agency-component/update-and-delete-travel-agency-modal';
import CreateTravelAgency from 'components/modal/travel-agency-component/create-travel-agency-modal';

import 'styles/travel-agency.scss';

function TravelAgency() {
  const [form] = Form.useForm();

  const {
    isOpenCreateTravelAgency,
    setIsOpenCreateTravelAgency,
    isOpenUpdateAndDeleteTravelAgency,
    setIsOpenUpdateAndDeleteTravelAgency
  } = useModal();
  const [selectValues, setSelectValues] = useState({});
  const [travelAgencyId, setTravelAgencyId] = useState<string>('');
  const { data: TravelAgencies, isLoading } = useGetTravelAgencies(selectValues);

  const onFinish: FormProps['onFinish'] = async (objValue: any) => {
    const truthyValues: any = Object.keys(objValue)
      .filter(key => Boolean(objValue[key]))
      .reduce((cur, next) => {
        return {
          ...cur,
          [next]: objValue[next]
        };
      }, {});
    if (truthyValues) {
      setSelectValues(truthyValues);
    }
  };

  const onHandleCreateNewTravelAgency = () => {
    setIsOpenCreateTravelAgency(true);
  };

  const onCancelCreateTravelAgency = () => {
    setIsOpenCreateTravelAgency(false);
  };

  const onHandleUpdateAndDeleteTravelAgency = (id: string) => {
    setIsOpenUpdateAndDeleteTravelAgency(true);
    setTravelAgencyId(id);
  };

  const onCancelUpdateAndDeleteTravelAgency = () => {
    setIsOpenUpdateAndDeleteTravelAgency(false);
  };

  return (
    <div className="pms-travel-agency">
      <Card title="Danh sách công ty du lịch" style={{ width: '100%' }}>
        <Form form={form} layout={'inline'} onFinish={onFinish} autoComplete="off">
          <div className="pms-travel-agency__filter-wrapper">
            <div className="pms-travel-agency__filter">
              <Form.Item name="id">
                <Input placeholder="Mã công ty" />
              </Form.Item>
              <Form.Item name="name">
                <Input placeholder="Tên công ty" />
              </Form.Item>
              <Button htmlType="submit" className="ant-btn-secondary btn-submit">
                Tìm kiếm
              </Button>
            </div>
            <Button onClick={() => onHandleCreateNewTravelAgency()}>Tạo công ty</Button>
            <CreateTravelAgency
              isOpen={isOpenCreateTravelAgency}
              onCancel={() => onCancelCreateTravelAgency()}
            />
            <UpdateAndDeleteTravelAgency
              isOpen={isOpenUpdateAndDeleteTravelAgency}
              onCancel={onCancelUpdateAndDeleteTravelAgency}
              travelAgencyId={travelAgencyId}
            />
          </div>
        </Form>
        <div className="pms-travel-agency__table">
          <Table
            loading={isLoading}
            rowKey="id"
            columns={columnTravelAgency}
            dataSource={TravelAgencies}
            onRow={record => {
              return {
                onClick: () => {
                  onHandleUpdateAndDeleteTravelAgency(String(record.id));
                }
              };
            }}
            bordered
            locale={{
              emptyText: <span className="empty-data">Không có dữ liệu</span>
            }}
            scroll={{ x: 2700, y: 'calc(100vh - 400px)' }}
            pagination={false}
          />
        </div>
      </Card>
    </div>
  );
}

export default TravelAgency;
